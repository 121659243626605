var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"body"}},[_vm._m(0),_c('div',{attrs:{"id":"wx_tit"}},[_vm._m(1),_vm._m(2),_c('div',{attrs:{"id":"wx_tit_main"}},[_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"weixin1"}}),_c('p',[_vm._v("微信店铺")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"fukuan"}}),_c('p',[_vm._v("会员中心")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"gongdanpaigongguanli"}}),_c('p',[_vm._v("在线预约")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"dianpu1"}}),_c('p',[_vm._v("在线商城")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"fukuan1"}}),_c('p',[_vm._v("移动支付")])],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{attrs:{"id":"wshopbox"}},[_c('p',{attrs:{"id":"on_wx"}},[_vm._v("限时免费体验大总管微店")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("你手机里的门店，在线预约一步到位")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("涨粉神器 | 在线预约 | 在线商城 | 微信会员营销")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("￥0")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.tyfun}},[_vm._v("免费体验")])]),_c('footers')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-banner"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/WXBANNER.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx_tit_header"}},[_c('p',[_vm._v("五大专属功能，手机预约一步到位")]),_c('span',[_vm._v("大总管微店能够自动同步你的门店商品信息到你的手机店铺，同时打通门店会员与微信粉丝数据，五大在线服务功能，能帮你真正实现数字化的智能门店")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-img"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wximg.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"600PX"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wximg002.png"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"sub"}},[_vm._v("微信店铺")]),_c('p',{attrs:{"id":"Ti"}},[_vm._v("微信店铺,线下门店，一键搬到手机")]),_c('span',[_vm._v("线下门店，一键搬到手机 无需设置，你的门店商品，会员，库存信息就能实时同步到微店，让你的客户可以直接通过微店在线预约，在线购买，体验就像在使用专业的购物APP")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MBcenter.png"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"sub"}},[_vm._v("微信会员中心")]),_c('p',{attrs:{"id":"Ti"}},[_vm._v("源源不断，回流现金")]),_c('span',[_vm._v("大总管微店可以打通门店会员与微信粉丝数据。会员无需到店、无需实体会员卡，即可通过微信查询积分余额，甚至在线充值购物卡。此外，大总管微店支持充值赠送等营销功能，后台营销信息通过微信一键推送，助您实现精准营销、快速回流资金")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"850PX"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/WEBSHOP.png"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"sub"}},[_vm._v("在线商城")]),_c('p',{attrs:{"id":"Ti"}},[_vm._v("无需分成给其他平台")]),_c('span',[_vm._v("试想入驻其他平台，不仅需要利润分成，顾客的偏好亦无法直接获得。大总管微店支持自建商城平台，通过微信下单，轻松拓展订单量，还有预约订位功能，提前预约支付，减少客户到店等待时间。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"paybgc"}},[_c('i',{staticClass:"el-icon-circle-check"}),_c('p',[_vm._v("免费帮你开通微信、支付宝支付")]),_c('span',[_vm._v("对接支付宝、微信支付不知如何下手？"),_c('br'),_vm._v(" 现在您拥有专人帮您免费开通手机支付，即刻拥有专用的收款二维码，安 全高效又快速，平均3秒内就能完成一次收付款。")])])
}]

export { render, staticRenderFns }